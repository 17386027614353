// extracted by mini-css-extract-plugin
export var arrow = "pager-module--arrow--yWeua";
export var arrowLeft = "pager-module--arrow-left--rr1JF";
export var arrowLink = "pager-module--arrow-link--VadYo";
export var arrowLinkLeft = "pager-module--arrow-link--left--leNrJ";
export var arrowLinkRight = "pager-module--arrow-link--right--1vAo8";
export var arrowRight = "pager-module--arrow-right--ZId1U";
export var linkText = "pager-module--link-text--Ht0TU";
export var pager = "pager-module--pager--1EaeS";
export var pagerItem = "pager-module--pager-item--ZFg3L";
export var pagerItemCurrent = "pager-module--pager-item--current--TvZTl";
export var pagerItemHideOnSmall = "pager-module--pager-item--hide-on-small--0V33F";
export var pagerItems = "pager-module--pager-items--L-0yN";